import React from 'react';
import {useNavigate} from 'react-router-dom';

function FooterComponent(props) {
  const navigate = useNavigate();

  function handlePageAvisodeprivacidad() {
    navigate('/AvisoDePrivacidad')
  }

  function handlePageAvisointegral() {
    navigate('/Aviso-Integral')
  }

  function handlePageAvisoLegal() {
    navigate('/Aviso-Legal')
  }
    return (
      <footer className="bg-white border-t border-gray-200 py-10 px-6 md:px-20 mt-[var(--gap-Sections)]">
      <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-3 gap-8 items-start">
        {/* Logo */}
        <div className='col-span-2 lg:col-span-1'>
          <img src="./assets/images/logos/t1pagosLogoFooter.png" alt="Logo" className="h-[4rem] w-auto" />
        </div>

        {/* Integraciones API */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Integraciones API</h3>
          <ul className="space-y-1">
            <li>
              <a href="https://docs.t1pagos.com/" className="text-gray-600 hover:text-gray-900"> 
                Documentación
              </a>
            </li>
            <li>
              <a href="https://docs.t1pagos.com/api.html" className="text-gray-600 hover:text-gray-900">
                Referencia API 
              </a>
            </li>
          </ul>
        </div>

        {/* Soporte */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Legales</h3>
          <ul className="space-y-1">
            <li>
              <button onClick={handlePageAvisointegral} className="text-gray-600 hover:text-gray-900">
                Aviso Integral
              </button>
            </li>
            {/* <li>
              <button onClick={handlePageAvisoLegal} className="text-gray-600 hover:text-gray-900">
                Aviso Legal
              </button>
            </li> */}
          </ul>
        </div>
      </div>

      <div className='container mx-auto mt-8'>
        <hr className="border-gray-200 my-8" />
        <div className='lg:flex grid gap-4 justify-between'>
          <div>
            <p className=" text-center">
              © 2024 T1 Pagos. Todos los derechos reservados.
            </p>
          </div>

          <div>
            <ul className="space-4 flex gap-[.5rem]">
              <li>
                <button onClick={handlePageAvisodeprivacidad} className="text-gray-600 underline hover:text-gray-900">
                  Aviso de privacidad
                </button>
              </li>
              <li>
                <hr className="h-6 border-l border-gray-300" />
              </li>
              <li>
                <button className="text-gray-600 underline hover:text-gray-900">
                  Términos y condiciones
                </button>
              </li>
            </ul>
          </div>
         
          <div className='flex space-x-4'>
            <svg className='w-auto h-[1rem]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
            <svg className='w-auto h-[1rem]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>
            <svg className='w-auto h-[1rem]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
            <svg className='w-auto h-[1rem]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
            <svg className='w-auto h-[1rem]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M282 256.2l-95.2-54.1V310.3L282 256.2zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9C42 315.9 42 256.3 42 256.3s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4z"/></svg>
          </div>

        </div>
      </div>
    </footer>
    );
}

export default FooterComponent;