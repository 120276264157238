import React, { useState } from "react";
import { Menu, X, ChevronDown } from 'lucide-react';
import { motion } from "framer-motion";
import {useNavigate} from 'react-router-dom';

function HeaderComponent(props) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const DropDownDesk = () => (
    <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.95 }}
    transition={{ duration: 0.3 }}
    >
    <div className={`${isDropdownOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'} transition-all duration-300 transform  absolute left-[-33rem] mt-2 w-[40rem] bg-white shadow-lg rounded-lg p-4 grid grid-cols-3 gap-4`}>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[2rem]" src="./assets/images/logos/Logo-T1-pagos.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_15.svg" alt="" />  Acepta pagos de forma dígital</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_16.svg" alt="" />  Seguro de contracargos</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_17.svg" alt="" />  Prevención de fraude</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_18.svg" alt="" />  Acepta pagos a meses sin intereses</li>
                      </ul>
                    </div>
                    
                  </div>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[2rem]" src="./assets/images/logos/Logo-T1-paginas.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                      <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_24.svg" alt="" />  E-commerce</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_25.svg" alt="" /> Digitalización de tu negocio</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_26.svg" alt="" /> Personalización</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_27.svg" alt="" /> Ventas 24/7</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_28.svg" alt="" /> Punto de venta</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_29.svg" alt="" /> Analítica</li>
                      </ul>
                    </div>

                  </div>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[1.8rem]" src="./assets/images/logos/Logo-T1-comercio.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                      <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_11.svg" alt="" /> Centralización de inventarios</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_12.svg" alt="" />Vende en múltiples marketplaces</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_13.svg" alt="" />Gestión de productos uníficada</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_14.svg" alt="" />Integrate a los mejores marketplaces</li>
                      </ul>
                    </div>

                  </div>
    </div>
  </motion.div>
  );
  const DropDownMobile = () => (
    <motion.div
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.95 }}
    transition={{ duration: 0.3 }}
    >
    <div className={`${isDropdownOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'} transition-all duration-300 transform mt-2 bg-white p-4 grid grid-cols-1 lg:grid-cols-3 gap-4`}>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[2rem]" src="./assets/images/logos/Logo-T1-pagos.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                      <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_15.svg" alt="" />  Acepta pagos de forma dígital</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_16.svg" alt="" />  Seguro de contracargos</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_17.svg" alt="" />  Prevención de fraude</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_18.svg" alt="" />  Acepta pagos a meses sin intereses</li>
                      </ul>
                    </div>

                  </div>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[2rem]" src="./assets/images/logos/Logo-T1-paginas.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                      <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_24.svg" alt="" />  E-commerce</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_25.svg" alt="" /> Digitalización de tu negocio</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_26.svg" alt="" /> Personalización</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_27.svg" alt="" /> Ventas 24/7</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_28.svg" alt="" /> Punto de venta</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_29.svg" alt="" /> Analítica</li>
                      </ul>
                    </div>

                  </div>
                  <div>
                    <div className="w-full border-b-[1px] border-gray-200">
                      <button><img className="h-[1.8rem]" src="./assets/images/logos/Logo-T1-comercio.png" alt="" /></button>
                    </div>
                    <div className="flex flex-col pt-3">
                      <ul className="gap-3 grid">
                      <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_11.svg" alt="" /> Centralización de inventarios</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_12.svg" alt="" />Vende en múltiples marketplaces</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_13.svg" alt="" />Gestión de productos uníficada</li>
                        <li className="flex gap-3 items-center "><img className="max-h-[1.3rem]" src="./assets/images/IconsSVG/Recurso_14.svg" alt="" />Integrate a los mejores marketplaces</li>
                      </ul>
                    </div>

                  </div>
    </div>
  </motion.div>
  );
  function handlePageHome() {
    navigate('/')
  }

  return (
    <>
      <nav className="relative py-4 bg-white container mx-auto z-10">
        <div className="flex items-center justify-between">
          {/* Logo y botón móvil */}
          <div className="flex items-center">
            <img onClick={handlePageHome} src="./assets/images/logos/Logo-T1-pagos.png" className="w-[145px] h-full" alt="" />
          </div>
          
          <button 
            className="md:hidden p-2 transition-transform duration-300"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 rotate-90 transition-all duration-300" />
            ) : (
              <Menu className="h-6 w-6 transition-all duration-300" />
            )}
          </button>

          {/* Menú desktop */}
          <div className="hidden md:flex items-center gap-10">
            {/* <a href="#" className="text-gray-600 hover:text-gray-900">Tarifas</a> */}
            <a href="#Soluciones" className="text-gray-600 hover:text-gray-900">Soluciones</a>
            <a href="https://docs.t1pagos.com/" className="text-gray-600 hover:text-gray-900">Documentación</a>
            <div className="relative group">
              <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex items-center gap-1 text-gray-600 hover:text-gray-900">
                Ecosistema <ChevronDown className={`h-4 w-4 transform transition-all ${isDropdownOpen ? '-rotate-90	' : ''}`} />
              </button>
              {isDropdownOpen && <DropDownDesk />}
            </div>
            <a href="mailto:contacto@t1pagos.com?subject=Consulta%20sobre%20T1%20Pagos&body=Hola,%20quisiera%20más%20información" className="text-gray-600 hover:text-gray-900">Contáctanos</a>
          </div>

          {/* Botones desktop */}
          <div className="hidden md:flex items-center gap-5">
            <button onClick={() => window.open(" https://admin.t1pagos.com", "_blank", "noopener,noreferrer")} className="btnBorderT1">Iniciar sesión</button>
            <button onClick={() => window.open("https://onboarding.t1pagos.com/onboarding", "_blank", "noopener,noreferrer")} className="btnSolidT1">Regístrate</button>
          </div>
        </div>

        {/* Menú móvil con animación */}
        <div className={`
          md:hidden absolute top-full left-0 right-0 bg-white shadow-lg
          overflow-hidden transition-all duration-200
          ${isMenuOpen ? 'max-h-[auto] opacity-100' : 'max-h-0 opacity-0'}
        `}>
          <div className="flex flex-col space-y-4 px-4 py-4">
            {/* <a href="#" className="text-gray-600 hover:text-gray-900">Tarifas</a> */}
            <a href="#Soluciones" className="text-gray-600 hover:text-gray-900">Soluciones</a>
            <a href="https://docs.t1pagos.com/" className="text-gray-600 hover:text-gray-900">Documentación</a>
             {/* Dropdown Mobile */}
             <div>
              <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex items-center gap-1 text-gray-600 hover:text-gray-900">
                Ecosistema <ChevronDown className={`h-4 w-4 transform transition-all ${isDropdownOpen ? 'rotate-180' : ''}`} />
              </button>
              {isDropdownOpen && <DropDownMobile />}
            </div>
            <a href="mailto:contacto@t1pagos.com?subject=Consulta%20sobre%20T1%20Pagos&body=Hola,%20quisiera%20más%20información" className="text-gray-600 hover:text-gray-900">Contáctanos</a>
            <button onClick={() => window.open(" https://admin.t1pagos.com", "_blank", "noopener,noreferrer")} className="px-4 py-2 text-red-600 hover:text-red-700 text-left">Iniciar sesión</button>
            <button onClick={() => window.open("http://onboarding.t1pagos.com/", "_blank", "noopener,noreferrer")} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
              Regístrate
            </button>
          </div>
        </div>
      </nav>
      <hr className="w-full h-[5px] shadow-xl"/>
    </>
  );
}

export default HeaderComponent;