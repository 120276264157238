import React from 'react';

const AvisoDePrivacidad = () => {
  return (
    <div className="w-full m-0 p-0 container mx-auto pt-9" style={{textAlign: 'justify'}}>
      <div className="flex flex-col items-center gap-8">
        <div className="w-full text-center" >
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold !text-[var(--text-BannerColor)] leading-[1.55] tracking-[.01px] pb-6">
            Aviso de Privacidad
          </h1>
        </div>
        
        <div className="w-full lg:w-5/6 px-4 md:px-6">
          <div className="space-y-6 lg:space-y-8 text-lg md:text-xl text-gray-600" style={{textAlign: 'justify'}}>
            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Quién dará tratamiento a sus datos personales?</h2>
              <p className="mb-4">
                El responsable de los datos personales que usted proporciona es Claro
                Pagos, S.A. de C.V. (en lo sucesivo "T1Pagos"), con domicilio para oír y
                recibir cualquier tipo de notificaciones ubicado en Lago Zúrich No. 245,
                Edificio Presa Falcón, piso 7, colonia Ampliación Granada, delegación
                Miguel Hidalgo, C.P. 11529, Ciudad de México, México.
              </p>
              <p>
                Para cualquier duda relacionada con la protección de sus datos
                personales podrá contactar a nuestro Departamento de Protección de Datos
                de T1Pagos a la dirección electrónica{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Qué información vamos a recabar?</h2>
              <p className="mb-4">
                T1Pagos para cumplir con las finalidades señaladas en el presente Aviso
                recabará y tratará datos personales de identificación, datos personales
                de identificación, datos de contacto, datos demográficos, datos de
                ubicación, datos patrimoniales, datos fiscales, datos sobre servicios
                contratados con terceros e información relativa a su capacidad de pago
                y/o aptitud para adquirir bienes, productos y/o servicios de terceros.
              </p>
              <p>
                En caso de que usted nos contacte por redes sociales, podremos recabar
                los datos de su perfil público de redes sociales para darle atención a
                sus peticiones y/o solicitudes de información en relación con los
                productos y/o servicio que ofrecemos, podremos dar tratamiento a la
                información contenida en su perfil público de la red social de que se
                trate, así como al contenido de sus publicaciones.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">No tratamiento de datos personales de menores de edad</h2>
              <p>
                T1Pagos no recaba datos personales o información directamente de menores
                de edad. En el supuesto de que se proporcionen datos relacionados con
                personas menores de edad asumiremos que la persona que los proporciona
                es el padre, madre y/o tutor legal, quien reconoce haber leído y
                aceptado el presente aviso de privacidad.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Para qué fines usamos su información?</h2>
              <p className="mb-4">
                T1Pagos tratará sus datos personales para las siguientes finalidades
                primarias y necesarias:
              </p>
              <ul className="space-y-2 list-inside list-disc pl-4" style={{textAlign: 'justify'}}>
                <li>
                  Identificarlo y conocer su comportamiento durante la contratación de
                  bienes, productos y servicios.
                </li>
                <li>
                  Realizar actividades de análisis de datos tendientes a determinar su
                  capacidad de pago y/o aptitud de adquisición de bienes, productos
                  y/o servicios de terceros.
                </li>
                <li>Realizar procedimientos de disociación de datos.</li>
                <li>
                  En caso de que usted lo solicite, procesar pagos a través de las
                  formas y mecanismos habilitados por T1Pagos.
                </li>
                <li>
                  Atender y/o dar seguimiento a investigaciones, quejas y/o
                  solicitudes de información en relación con los productos y/o
                  Servicios que ofrecemos.
                </li>
                <li>
                  Generar y llevar a cabo procedimientos de disociación para generar
                  audiencias segregadas para que terceros, socios comerciales y/o
                  Empresas del Grupo le ofrezcan productos y servicios.
                </li>
                <li>
                  Para contactar a través de llamadas telefónicas, envío de
                  comunicaciones a través de correos electrónicos, mensajes cortos y
                  demás medios de comunicación físicos y/o electrónicos, servicios de
                  mensajería instantánea (WhatsApp, Telegram, entre otros que T1Pagos
                  considere convenientes). Lo anterior, para realizar actividades de
                  contacto con el propósito de brindarle información sobre los
                  Servicios que ofrecemos, informarle sobre cualquier beneficio al que
                  se hubiere hecho acreedor como consecuencia de los mismos y dar
                  cumplimiento a las obligaciones que usted haya adquirido con
                  nosotros.
                </li>
                <li>
                  Dar atención a sus solicitudes de información, quejas o
                  reclamaciones.
                </li>
                <li>
                  Dar cumplimiento a las obligaciones derivadas de la relación
                  jurídica con usted.
                </li>
                <li>
                  Dar cumplimiento a las políticas y procedimientos de carácter
                  interno.
                </li>
                <li>
                  Dar cumplimiento a obligaciones previstas en la normatividad
                  aplicable, incluyendo la atención de requerimientos debidamente
                  fundados y motivados emitidos por autoridades competentes.
                </li>
              </ul>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Qué otros usos podemos darle a su información?</h2>
              <p className="mb-4">
                De manera adicional, si usted no se opone, T1Pagos podrá mantener su
                información personal para las siguientes finalidades que no son
                necesarias, pero que nos permiten y facilitan brindarle una mejor
                atención:
              </p>
              <ul className="space-y-2 list-inside list-disc pl-4">
                <li>Para posibles contactos posteriores.</li>
                <li>
                  Informarle del lanzamiento o cambios de nuevos productos, servicios,
                  promociones y ofertas propias y/o de terceros, incluyendo el envío
                  de comunicaciones a través de correos electrónicos, llamadas
                  telefónicas, mensajes cortos y demás medios de comunicación físicos
                  y/o electrónicos.
                </li>
                <li>
                  Para enviarle publicidad y comunicaciones con fines de
                  mercadotecnia, telemarketing entre otras.
                </li>
              </ul>
              <p className="mt-4">
                En caso de que no desee que sus datos personales sean tratados para
                alguna o todas las finalidades adicionales, desde este momento usted nos
                puede comunicar lo anterior al correo{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
              </p>
              <p className="mt-4">
                La negativa para el uso de sus datos personales para fines adicionales,
                no podrá ser un motivo para negarle los servicios solicitados o dar por
                terminada la relación establecida con nosotros.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿A qué terceros podemos transferir su información?</h2>
              <p className="mb-4">
                T1Pagos podrá transferir, sin requerir de su consentimiento, los datos
                personales necesarios a autoridades competentes, tanto nacionales como
                internacionales, en los casos legalmente previstos, así como a cualquier
                empresa Subsidiaria, afiliada, parte relacionada, controlada,
                controladora o bajo control Común, las cuales operan bajo políticas de
                privacidad que cumplen con las disposiciones aplicables
                correspondientes, para dar cumplimiento a las finalidades señaladas en
                el presente aviso.
              </p>
              <p className="mb-4">
                Para conocer cuáles son las Empresa subsidiaria, afiliada, parte
                relacionada, controlada, controladora o bajo control Común a quienes sus
                datos son transferidos puede consultarlo en{" "}
                <a
                  href="http://www.gsanborns.com.mx/perfil-corporativo.html"
                  className="text-[var(--colorT1)] font-bold hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.gsanborns.com.mx/perfil-corporativo.html
                </a>
              </p>
              <p className="mb-4">
                T1Pagos podrá transferir sin requerir de su consentimiento los datos
                personales necesarios a: socios comerciales y/o terceros con los que
                tengamos celebrados contratos de prestación de servicios para prestar
                servicios de entrega de bienes y productos, postventa, gestionar cuentas
                y registros de clientes, enviar comunicación relativa a productos o
                servicios que pueden ser de su interés, programas de lealtad, así como
                comunicaciones de marketing, con los que tengamos celebrados acuerdos
                comerciales o de colaboración para fines relacionados con la validación
                y autenticación de su identidad, conforme a las finalidades descritas en
                el presente Aviso de Privacidad. Además, T1Pagos podrá compartir los
                datos personales necesarios a autoridades de los distintos órdenes de
                gobierno e instancias de la administración pública local o federal, para
                que dicha información sea consignada como prueba en un procedimiento
                judicial o administrativo, según se requiera.
              </p>
              <p>
                En caso de que no desee que sus datos personales sean transferidos para
                alguna o todas las finalidades señaladas, desde este momento usted nos
                puede comunicar lo anterior al correo{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h2>
              <p className="mb-4">
                Usted o su representante legal podrá ejercer cualquiera de los derechos
                de acceso, rectificación, cancelación u oposición (en lo sucesivo
                "derechos Arco"), así como revocar su consentimiento para el tratamiento
                de sus datos personales enviando un correo electrónico al Departamento
                de Protección de Datos de T1Pagos a la dirección electrónica{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
                .
              </p>
              <p className="mb-4">
                Su petición deberá ser realizada a través del Formato de Solicitud de
                Derechos ARCO, el cual debe ser solicitado a la dirección de correo
                electrónico antes señalada. Para que el Departamento de Protección de
                Datos de T1Pagos pueda darle seguimiento a su solicitud, usted o su
                representante legal, deberá acreditar correctamente su identidad para lo
                que es necesario que complete todos los campos indicados en el Formato y
                lo acompañe con copia de alguna de las identificaciones oficiales
                vigentes que se señalan en el mismo. En caso de que la información
                proporcionada sea errónea o insuficiente, o bien, no se acompañen los
                documentos de acreditación correspondientes, nuestro Departamento de
                Protección de Datos, dentro de los cinco (5) días hábiles siguientes a
                la recepción de la solicitud, podrá requerirle que aporte los elementos
                o documentos necesarios para dar trámite a la misma. Usted contará con
                diez (10) días hábiles para atender el requerimiento, contados a partir
                del día siguiente en que lo haya recibido. De no dar respuesta en dicho
                plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro
                Departamento de Protección de Datos le comunicará la determinación
                adoptada, en un plazo máximo de veinte (20) días hábiles contados desde
                la fecha en que se recibió la solicitud, a efecto de que, si resulta
                procedente, haga efectiva la misma dentro de los quince (15) días
                hábiles siguientes a que se comunique la respuesta. Para obtener mayor
                información sobre los procedimientos, requisitos y plazos para el
                ejercicio de sus Derechos ARCO y/o revocación del consentimiento
                contacte al Departamento de Protección de Datos de T1Pagos.
              </p>
              <p className="mb-4">
                Le recordamos que, si usted desea eliminar sus datos, deberá promover su
                derecho de cancelación, en los términos antes descritos.
              </p>
              <p>
                En caso de que su solicitud de cancelación, oposición o revocación sea
                procedente, según corresponda se dejarán de usar sus datos, para la
                finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas
                en términos de lo que establece la legislación de la materia.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>
              <p>
                Usted podrá limitar el uso o divulgación de sus datos personales
                enviando su solicitud a la dirección{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
                . En caso de que su Solicitud sea procedente se le registrará en el
                listado de exclusión propio de T1Pagos. Para mayor información favor de
                contactar al Departamento de Protección de Datos de T1Pagos.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">
                ¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso
                de Privacidad?
              </h2>
              <p>
                T1Pagos se reserva el derecho, bajo su exclusiva discreción, de cambiar,
                modificar, agregar o eliminar partes del presente Aviso de Privacidad en
                cualquier momento. En tal caso, T1Pagos mantendrá su Aviso de Privacidad
                Integral vigente en el sitio web{" "}
                <a
                  href="https://www.t1pagos.com/aviso_privacidad.html"
                  className="text-[var(--colorT1)] font-bold hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aviso Privacidad
                </a>
                . Le recomendamos visitar periódicamente esta página con la finalidad de
                informarse si ocurre algún cambio al presente.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Cómo puede hacernos llegar sus dudas?</h2>
              <p>
                Nuestro Departamento de Protección de Datos queda a sus órdenes para
                proporcionarle cualquier información adicional que requiera o, en su
                caso, para resolver cualquier duda que pudiera surgirle en materia de
                privacidad y protección de datos personales, para lo que podrá
                contactarnos a través del correo electrónico{" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                  arco@t1pagos.com
                </a>
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">¿Cómo protegemos sus transacciones?</h2>
              <p>
                Con el propósito de proteger las transacciones que usted realiza a
                través de nuestro sitio web, tenemos implementado un servidor seguro
                bajo el protocolo SSL (Secure Socket Layer) de tal menara que la
                información que nos envía se transmite encriptada para asegurar su
                protección.
              </p>
              <p className="mt-4">
                Para verificar que se encuentra en un entorno protegido, asegúrese de
                que aparezca una \"S\" en la barra de navegación \"httpS\"://.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">Recomendaciones de seguridad</h2>
              <p>
                Es importante que elija una contraseña única y distinta a la que uses en
                otros sitios de internet. Asimismo, es de vital importancia que no
                comparta su contraseña con ningún tercero, si cree que alguien pudiere
                llegar a tener conocimiento de su contraseña cámbiela de inmediato.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">INAI</h2>
              <p>
                En caso de considerarlo necesario, le informamos que tiene el derecho de
                acudir ante el Instituto Nacional de Transparencia, Acceso a la
                Información y Protección de Datos Personales (INAI) para hacer efectivos
                sus derechos.
              </p>
            </section>

            <section style={{textAlign: 'justify'}}>
              <h2 className="text-2xl md:text-3xl font-extrabold mb-4">Aceptación de Aviso de Privacidad</h2>
              <p>
                Al hacer uso de nuestros servicios y proporcionar sus datos personales,
                significa que usted ha leído, entendido, y acordado los términos
                expuestos en el presente Aviso de Privacidad y consiente el tratamiento
                de sus datos personales según lo previsto en este último.
              </p>
            </section>
          </div>
        </div>
      </div>

      <div className="w-full m-0 p-0 container mx-auto pt-9">
      {/* <div className="flex flex-col items-center gap-8">
        <div className="w-full text-center">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold !text-[var(--text-BannerColor)] leading-[1.55] tracking-[.01px] pb-6">
          DISCLAIMER 
          </h1>
        </div>
        
        <div className="w-full lg:w-5/6 px-4 md:px-6" style={{textAlign: 'justify'}}>
          <div className="space-y-6 lg:space-y-8 text-lg md:text-xl text-gray-600">
            <section style={{textAlign: 'justify'}}>
                <p className="mb-4">
                    Le informamos que Claro Pagos, S.A. de C.V. (en lo sucesivo “T1Pagos”), con domicilio para oír y recibir cualquier tipo de notificaciones ubicado en Lago Zúrich No. 245, Edificio Presa Falcón, piso 7, colonia Ampliación Granada, delegación Miguel Hidalgo, C.P. 11529, Ciudad de México, México. T1Pagos para la prestación de sus servicios, recabará información relativa a personas morales y/o de personas físicas en su calidad de comerciantes.  
                </p>
                <p>
                    En este sentido, le informamos que toda la información que nos llegue a proporcionar las personas físicas en su calidad de comerciantes y/o las  personas morales no se encuentra comprendida dentro del ámbito de aplicación de la Ley Federal de Protección de Datos Personales en Posesión de Particulares y demás normatividad aplicable.
                </p>
                <p className="mb-4">
                    No obstante, lo anterior, para T1Pagos la confidencialidad y debida protección de su información es de máxima importancia, razón por la cual está comprometida a manejar su información de manera responsable y conforme a las disposiciones legales aplicables.
                </p>
                <p>
                    Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de T1Pagos a la dirección electrónica 
                    {" "}
                <a href="mailto:arco@t1pagos.com" className="text-[var(--colorT1)] font-bold hover:underline">
                    arco@t1pagos.com
                </a>
                </p>
            </section>
            <section className="pt-4 border-t border-gray-200" style={{textAlign: 'justify'}} >
              <p className="text-lg font-bold text-gray-800">
                Fecha de última actualización: Octubre de 2024
              </p>
            </section>
          </div>
        </div>
      </div> */}
    </div>

    </div>
  );
};

export default AvisoDePrivacidad;