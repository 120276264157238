import React from 'react';
import Carousel from "react-multi-carousel";


const CarouselComponent = ({ children, itemsMobile, speed, arrows }) => {

const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3, partialVisibilityGutter: 40 },
    tablet: { breakpoint: { max: 1024, min: 768 }, items: 3, partialVisibilityGutter: 30 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: itemsMobile ? itemsMobile : 1, partialVisibilityGutter: 30},
  };
  
    return (
       <div className="w-full max-w-[95vw] lg:max-w-[90vw] mx-auto">
         <Carousel
           responsive={responsive}
           infinite
           autoPlay
           customTransition="transform 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94)"
           autoPlaySpeed={speed ? speed : 3000}
           arrows={false}
           showDots={false}
         >
           {children}
         </Carousel>
       </div>
     );
}

export default CarouselComponent;