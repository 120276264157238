import React, {useState, useEffect} from 'react';
import Lottie from 'lottie-react';
import LottieBanner from '../lottie/Home_V2.json';
import LottieLink from '../lottie/Paso1-3_.json';
import LottieDashboardMobile from '../lottie/ConoceunPoco_Mobile_V2.json';
import LottieDashboard from '../lottie/ConoceunPoco.json';
import "react-multi-carousel/lib/styles.css";
import CarouselComponent from '../components/CarouselComponent';

const AnimatedBlurText = () => {
  const texts = [
    "transacciones seguras y eficientes",
    "el menor porcentaje de fraudes",
    "integraciones rápidas y seguras"
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); 
    };
    
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const getPosition = (index) => {
    const relativeIndex = (index - activeIndex + texts.length) % texts.length;
    return relativeIndex;
  };

  return (
    <div className="relative h-[5.3rem] lg:h-[7.9rem] xl:h-[12rem] overflow-hidden">
      <div className="relative h-full w-full">
        {texts.map((text, index) => {
          const position = getPosition(index);
          
          return (
            <div
              key={index}
              className={`absolute w-full transition-all duration-1000 ease-in-out
                ${isMobile ? 'md:transform-none' : ''}`}
              style={{
                transform: isMobile 
                  ? `translateY(${position === 0 ? '0%' : '-100%'})`
                  : `translateY(${position * 100}%)`,
                opacity: isMobile 
                  ? position === 0 ? 1 : 0
                  : position === 1 ? 1 : 0.3,
              }}
            >
              <p className="text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-extrabold text-[var(--colorT1)] text-center lg:text-left py-[.2rem] md:py-[.2rem] lg:py-[.2rem] xl:py-[.7rem]">
                {text}
              </p>
            </div>
          );
        })}
            <div className="absolute z-0 top-0 left-0 w-full h-[15%] lg:h-[25%] bg-gradient-to-b from-white via-white/90 to-transparent " />
            <div className="absolute z-0 bottom-0 left-0 w-full h-[25%] bg-gradient-to-t from-white via-white/90 to-transparent" />
       
      </div>
    </div>
  );
};

function BannerPrincipal() {
  return (
  <div className="container mx-auto pt-9 lg:pt-0">
    <div className="flex flex-col lg:flex-row items-center gap-8">
      <div className="w-full lg:w-1/2 text-center lg:text-left">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold !text-[var(--text-BannerColor)] leading-[1.55] tracking-[.01px] pb-2">
          Aumenta tu negocio con
        </h1>

        {/* Text animation container */}
        <div className="mx-auto">
          <AnimatedBlurText />
        </div>
        <p className="text-lg md:text-2xl text-gray-600 mb-6 md:mb-8 ">
          Cobra rápido, protege tus ventas y brinda una experiencia de pago sin fricciones en una plataforma segura y fácil de integrar.
        </p>
        <div className='w-full flex justify-center lg:justify-start'>
          <button onClick={() => window.open("https://onboarding.t1pagos.com/onboarding", "_blank", "noopener,noreferrer")} className="lg:w-[20rem] w-[90%] btnSolidT1 !py-[1rem] font-extrabold text-lg ">
            Comenzar ahora
          </button>
        </div>
        
      </div>
      <div className="w-full lg:w-1/2">
        <Lottie animationData={LottieBanner} loop={true} autoplay={true} />
      </div>
    </div>
  </div>
  );
}

const WhyChoose = () => {
  return (
    <section className="container mx-auto">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          {/* Columna izquierda - Títulos */}
          <div className="space-y-4">
          <h1 className="text-3xl md:text-6xl font-bold text-center lg:text-left">
            ¿Por qué elegir <br />
            <span className="inline-flex  gap-2 items-center">
              <img src="./assets/images/logos/Logo-T1-pagos.png" className="lg:w-[17rem] w-[10rem] h-full" alt="" /> ?
            </span>
          </h1>
            
          </div>

          {/* Columna derecha - Tarjetas */}
          <div className="space-y-8">
            {/* Tarjeta 1 */}
            <div className="p-6 hover:shadow-md transition-all duration-300">
              <div className="flex items-start gap-4">
                <img src="./assets/images/icons/aprobacion.svg" alt="" />
                <div>
                  <h3 className="lg:text-2xl text-xl font-extrabold mb-2">
                    Aprobación más alta
                  </h3>
                  <p className='lg:text-2xl text-xl'>
                    Incrementa tus ventas con una tasa de aprobación superior al 99% del mercado.
                  </p>
                </div>
              </div>
            </div>

            {/* Tarjeta 2 */}
            <div className="p-6 hover:shadow-md transition-all duration-300">
              <div className="flex items-start gap-4">
                <img src="./assets/images/icons/antifraude.svg" alt="" />
                <div>
                  <h3 className="lg:text-2xl text-xl font-extrabold mb-2">
                    Antifraude personalizado
                  </h3>
                  <p className='lg:text-2xl text-xl'>
                    Protege cada una de tus transacciones con nuestro antifraude adaptado a tu negocio.
                  </p>
                </div>
              </div>
            </div>

            {/* Tarjeta 3 */}
            <div className="p-6 hover:shadow-md transition-all duration-300">
              <div className="flex items-start gap-4">
              <img src="./assets/images/icons/seguro.svg" alt="" />
                <div>
                  <h3 className="lg:text-2xl text-xl font-extrabold  mb-2">
                    Seguro de contracargos
                  </h3>
                  <p className='lg:text-2xl text-xl'>
                    Nuestra opción de seguros de contracargo elimina las pérdidas por disputas y fraudes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Stats = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 px-4 md:px-6 container mx-auto">

    <div className="text-left px-5 p-4 bg-white hover:shadow-lg shadow-none transition-all duration-300 relative h-[11rem] lg:h-auto">
      <div className="absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-red-500/70 via-red-500 to-red-500/70 mt-12"></div>

      <div className='w-full absolute top-0 left-0'>
      <span> <img src="./assets/images/icons/disponibilidad.svg" alt="" /></span>
      </div>
      <div className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-1 mt-8 ml-5">99.9%</div>
      <div className="lg:text-2xl text-xl">Disponibilidad en el <br/> sistema</div>
    </div>

    <div className="text-left px-5 p-4 bg-white hover:shadow-lg shadow-none transition-all duration-300 relative h-[11rem] lg:h-auto">
      <div className="absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-red-500/70 via-red-500 to-red-500/70 mt-12"></div>

      <div className='w-full absolute top-0 left-0'>
      <span><img src="./assets/images/icons/metrica2.svg" alt="" /></span>
      </div>
      <div className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-1 mt-8 ml-5">+85%</div>
      <div className="lg:text-2xl text-xl">Tasa de aprobación</div>
    </div>

    <div className="text-left px-5 p-4 bg-white hover:shadow-lg shadow-none transition-all duration-300 relative h-[11rem] lg:h-auto">
      <div className="absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-red-500/70 via-red-500 to-red-500/70 mt-12"></div>

      <div className='w-full absolute top-0 left-0'>
      <span><img src="./assets/images/icons/metrica3.svg" alt="" /></span>
      </div>
      <div className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-1 mt-8 ml-5">-0.5%</div>
      <div className="lg:text-2xl text-xl">Tasa de contracargos</div>
    </div>

    <div className="text-left px-5 p-4 bg-white hover:shadow-lg shadow-none transition-all duration-300 relative h-[11rem] lg:h-auto">
      <div className="absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-red-500/70 via-red-500 to-red-500/70 mt-12"></div>

      <div className='w-full absolute top-0 left-0'>
      <span><img src="./assets/images/icons/metrica4.svg" alt="" /></span>
      </div>
      <div className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-1 mt-8 ml-5">$25.4B</div>
      <div className="lg:text-2xl text-xl">Monto total procesado</div>
    </div>

  </div>
);

const GenerateLink = () => (
  <div className='container mx-auto'>
    <div className='w-full flex flex-col items-center gap-[var(--gap-Sections)]'>

      <div className='w-full flex flex-col items-center text-center'>
        <h1 className='text-3xl md:text-6xl font-bold'>Haz tu link en segundos</h1>
      </div>
      <div className='w-full flex'>
        <Lottie animationData={LottieLink} loop={true} autoplay={true} />
      </div>

    </div>
  </div>
);

const IntegrateApi = () => {
  const solutions = [
    {
      icon: (
        <img src='./assets/images/icon_integracion/gateway_pagos.png' className='w-[4rem]' alt='gateway_pagos' />
      ),
      title: "Gateway de pagos",
      description: "Facilita pagos en línea con seguridad y cumplimiento PCI para tu negocio."
    },
    {
      icon: (
        <img src='./assets/images/icon_integracion/prevencion.png' className='w-[4rem]'  alt='prevencion' />
      ),
      title: "Herramienta de prevención de fraude",
      description: "Evita fraudes con T1 Score y Cybersource en cada transacción."
    },
    {
      icon: (
        <img src='./assets/images/icon_integracion/proteccion.png' className='w-[4rem]'  alt='proteccion' />
      ),
      title: "Equipo de protección de fraudes",
      description: "Supervisión activa y análisis experto para detectar y mitigar riesgos en tu negocio."
    },
    {
      icon: (
        <img src='./assets/images/icon_integracion/integracion.png' className='w-[4rem]'  alt='integracion' />
      ),
      title: "Integración rápida",
      description: "Conecta tu e-commerce de forma ágil y eficiente a través de nuestra API."
    },
    {
      icon: (
        <img src='./assets/images/icon_integracion/resguardo.png' className='w-[4rem]'  alt='resguardo' />
      ),
      title: "Resguardo seguro",
      description: "Almacena datos de forma cifrada en nuestra bóveda virtual de alta seguridad."
    },
    {
      icon: (
        <img src='./assets/images/icon_integracion/cargos.png' className='w-[4rem]' alt='cargos' />
      ),
      title: "Cargos recurrentes",
      description: "Gestiona pagos automáticos de manera segura y sin fricciones."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16 flex flex-col gap-[var(--gap-Sections)]" id='Soluciones'>
      <h1 className="text-3xl md:text-6xl font-bold text-center">
        Opciones de Integración y Soluciones
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <div 
            key={index}
            className="bg-white rounded-3xl p-8 flex flex-col items-start gap-4 transition-all duration-300 border-[1px]"
          >
            <div className="mb-2">
              {solution.icon}
            </div>
            <h3 className="text-2xl font-bold">
              {solution.title}
            </h3>
            <p className="text-gray-600 text-lg">
              {solution.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const DiscoverOurPlatform = () => (
  <div className='container mx-auto'>
    <div className='w-full flex flex-col items-center gap-[var(--gap-Sections)]'>

      <div className='w-full flex flex-col items-center text-center'>
        <h1 className='text-3xl md:text-6xl font-bold'>Conoce un poco de nuestra plataforma</h1>
      </div>
      <div className='w-full flex items-center justify-center'>
        <Lottie className='hidden lg:block' animationData={LottieDashboard} loop={true} autoplay={true} />
        <Lottie className='block lg:hidden w-[80%]' animationData={LottieDashboardMobile} loop={true} autoplay={true} />
      </div>

    </div>
  </div>
);

function LiderEnterprice() {

  const testimonials = [
    // {
    //   name: "Marco Antonio",
    //   position: "CEO Casa de Toño",
    //   text: "¡T1 Pagos ha revolucionado nuestro proceso de pagos!",
    //   image: "https://via.placeholder.com/50",
    // },
    {
      name: "Alexis Reséndiz Mesa",
      position: "CEO PASE",
      text: "La integración fue rápida y sencilla.",
      image: "https://via.placeholder.com/50",
    },
    {
      name: "Rafael León Melendez",
      position: "CEO Pirma",
      text: "Excelente soporte y seguridad.",
      image: "https://via.placeholder.com/50",
    },
    {
      name: "Carlos Zenteno",
      position: "CEO Claro Colombia",
      text: "Los links de pago nos han facilitado mucho la vida.",
      image: "https://via.placeholder.com/50",
    },
  ];

  return (
    <div>
    <div className='w-full flex flex-col items-center gap-[var(--gap-Sections)]'>

      <div className='w-full flex flex-col items-center text-center container mx-auto'>
        <h1 className='text-3xl md:text-6xl font-bold'>Empresas líderes que confían en nosotros</h1>
      </div>

      <div className='w-full flex'>
        <CarouselComponent>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-xl border-[#e7e7e7] border-[1px] m-[1rem] p-6 flex flex-col gap-3 w-full lg:w-[85%] mx-0 lg:mx-2">
              <div className="flex items-center gap-3">
                {/* <img src={testimonial.image} alt={testimonial.name} className="w-10 h-10 rounded-full object-cover" /> */}
                <div>
                  <p className="font-bold lg:text-lg text-sm text-gray-900">{testimonial.name}</p>
                  <p className="text-gray-500 lg:text-lg text-sm">{testimonial.position}</p>
                </div>
              </div>
              <p className="lg:text-xl text-lg">{testimonial.text}</p>
            </div>
          ))}
        </CarouselComponent>
      </div>

    </div>
  </div>
  );
 
};

const ClientsImage = () => (
  <div className='container mx-auto '>
    <div className=' grid-cols-3 md:grid-cols-6 items-center gap-4 justify-items-center lg:grid hidden'>
        <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/pirma.png" alt="" />
        <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/telcel.png" alt="" />
        <img className=' w-[130px] h-auto 'src="./assets/images/Marcas/pase.png" alt="" />
        <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/sears.png" alt="" />
        <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/cruz_azul.png" alt="" />
        <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/sanborns.png" alt="" />
        {/* <img className=' w-[130px] h-auto ' src="./assets/images/Marcas/casa_de_tono.png" alt="" /> */}
    </div>
    <div className='lg:hidden flex justify-center'>
    <CarouselComponent itemsMobile={3} speed={900} >
        <img className=' w-[130px] h-auto p-4' src="./assets/images/Marcas/pirma.png" alt="" />
        <img className=' w-[130px] h-auto p-4 ' src="./assets/images/Marcas/telcel.png" alt="" />
        <img className=' w-[130px] h-auto p-4 'src="./assets/images/Marcas/pase.png" alt="" />
        <img className=' w-[130px] h-auto p-4 ' src="./assets/images/Marcas/sears.png" alt="" />
        <img className=' w-[130px] h-auto p-4 ' src="./assets/images/Marcas/cruz_azul.png" alt="" />
        <img className=' w-[130px] h-auto p-4 ' src="./assets/images/Marcas/sanborns.png" alt="" />
    </CarouselComponent>

    </div>
  </div>
);

const SectionText = () => (
  <div className='container mx-auto'>
    <div className='grid grid-cols-1 md:grid-cols-2 items-center lg:gap-0 gap-8'>
      {/* seccion izquierda */}
      <div className='width-full text-center'>
          <h1 className='text-3xl md:text-5xl font-bold !leading-snug	'>
            ¿Tienes más de 10 mil <br/> transacciones al mes?
          </h1>
      </div>
      {/* seccion derecha */}
      <div className='w-full text-center flex justify-center'>
        <a href="mailto:contacto@t1pagos.com?subject=Consulta%20sobre%20T1%20Pagos&body=Hola,%20quisiera%20más%20información" className="lg:w-[25rem] w-[90%] btnSolidT1 !py-[1rem] font-extrabold text-lg">
            Agenda tu cita
        </a>
      </div>
    </div>
  </div>
);


const SectionTextTwo = () => (
  <div className='w-full flex container mx-auto'>
    <div className='grid grid-cols-1 items-center gap-[var(--gap-Sections)]'>
      <div className='p-8 block lg:flex lg:gap-6 items-center'>

        <p className='lg:text-xl text-lg text-left mx-0 '>
          
          Nuestra plataforma está certificada por PCI-DSS y emplea las mejores {''}
          <span className='hidden lg:inline-block'>prácticas para garantizar la seguridad de tus datos y transacciones.</span>
          <span className='lg:hidden'>prácticas para garantizar <br/> la seguridad de tus datos y transacciones. </span>
        </p>
        <div className='lg:w-[25rem] w-full lg:pt-0 pt-4  flex justify-center'>
          <img  src="./assets/images/Marcas/PCI.svg" alt="" />
        </div>

      </div>
      
    </div>
  </div>
);

const homePage = () => {
  return (
    <div className="w-full m-0 p-0 grid gap-[var(--gap-Sections)]">
      <BannerPrincipal />
      <WhyChoose />
      <Stats />
      <GenerateLink />
      <IntegrateApi />
      <DiscoverOurPlatform />
      <LiderEnterprice />
      <ClientsImage />
      <SectionText />
      <SectionTextTwo />
    </div>
  );
};

export default homePage;