import React from 'react';
import HomePage from './pages/HomePage';
import AvisoIntegralPage from './pages/AvisoIntegral'
import AvisoLegalPage from './pages/AvisoLegal'
import AvisoDePrivacidad from './pages/AvisoDePrivacidad'
import Layout from './components/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


export default function App() {


    return (
    <Router>
        <div className="App">
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/Aviso-Integral" element={<AvisoIntegralPage />} />
                    {/* <Route path="/Aviso-Legal" element={<AvisoLegalPage />} /> */}
                    <Route path="/AvisoDePrivacidad" element={<AvisoDePrivacidad />} />
                </Routes>
            </Layout>
        </div>
    </Router>
    );
}